.detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
}
.detail-content {
  margin: 0 auto;
  width: 43%;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center; /* 아이템들이 공간을 고르게 나누게 함 */
  width: 100%;
  flex-wrap: wrap; /* 화면이 작아지면 줄바꿈 허용 */
  gap: 10px;
}

@media (max-width: 600px) {
  .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

.map-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: flex;
  justify-content: left;
}

.comment-section {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comment-list {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.small-button {
  font-size: 10px; /* 텍스트 크기 */
  padding: 4px 8px; /* 버튼 내부 여백 */
  height: 20px; /* 버튼 높이 */
  line-height: 1; /* 텍스트 줄 높이 */
}
